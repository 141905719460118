import get from "lodash/get";
import React from "react";

import Metas from "components/metas";
import { constants } from "@newsela/angelou";
import SearchForm from "components/searchForm";
import { useUserContext } from "contexts/userContext";

const angelouUI = constants.colors.ui;
const greyDark = angelouUI.greyDark["500"];

export default function ContactPage() {
  const user = get(useUserContext(), "user", {});
  if (!user.isLoaded) {
    return null;
  }
  const userSchools = user.schools || [];
  const userDistrictIds = userSchools
    .map((school) => {
      return school.districts.map((district) => {
        return district.id;
      });
    })
    .flat();

  // For users associated with K-12 Inc., we want them going to K-12's support. Direct
  // them there accordingly.
  if (userDistrictIds.includes(21347)) {
    return (
      <>
        <Metas title="Contact Us" />
        <SearchForm />
        <div
          css={{
            margin: "1rem",
          }}
        >
          <h1
            css={{
              color: greyDark,
              marginBottom: "1rem",
              textDecoration: "none",
              outline: "none",
            }}
          >
            Contact Support
          </h1>
          <p>
            K12 Newsela subscribers should reach out to K12’s Support Teams.
          </p>
          <h2
            css={{
              color: greyDark,
              margin: "1rem 0 .5rem",
              textDecoration: "none",
              outline: "none",
            }}
          >
            Teachers
          </h2>
          <p>
            Teacher Support Center <br />
            https://teacherhelp.k12.com
          </p>
          <h3
            css={{
              color: greyDark,
              margin: "1rem 0 .5rem",
              textDecoration: "none",
              outline: "none",
            }}
          >
            Students and Families
          </h3>
          <p>
            Customer Care and Tech Support <br />
            https://help.k12.com <br />
            (866)512-2273
          </p>
          <h3
            css={{
              color: greyDark,
              margin: "1rem 0 .5rem",
              textDecoration: "none",
              outline: "none",
            }}
          >
            Administrators and Ops Management
          </h3>
          <p>
            Service Now Help Desk <br />
            https://opssupport.k12.com/
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <Metas title="Contact Us" />
      <SearchForm />
      <div
        css={{
          margin: "1rem",
        }}
      >
        <title>Contact Newsela Support</title>
        <h4
          css={{
            color: greyDark,
            marginBottom: "1rem",
            textDecoration: "none",
            outline: "none",
          }}
        >
          Contact Newsela Support
        </h4>
        <div>
          <p>
            Fill out the form below to receive support. We will respond promptly
            via email. If your request is urgent and you have a Newsela
            subscription, we recommend using Live Chat (available Monday-Friday,
            8AM-8PM ET).
          </p>
        </div>
        <div
          css={{
            width: "100%",
            height: "100%",
            "& iframe": {
              height: "900px",
              width: "100%",
            },
          }}
        >
          <iframe
            title="Support Form"
            src="https://tfaforms.com/5099872"
            frameBorder="0"
          />
        </div>
      </div>
    </>
  );
}
